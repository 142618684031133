import _ from 'lodash';
import { ChevronDown } from 'react-feather';
import Select, { components } from 'react-select';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDown />
    </components.DropdownIndicator>
  );
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#ffffff' : '#333333',
    fontWeight: state.isSelected ? 600 : 400,
    backgroundColor: state.isSelected ? '#00cccb' : '#ffffff',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
  }),
  input: (styles) => ({ ...styles, ...optionSelected() }),
  singleValue: (styles, { data }) => ({ ...styles, ...optionSelected(data) }),
};

function SelectCustom({
  isMulti = false,
  options = [],
  disabled,
  classNames = '',
  value = '',
  placeholder = '',
  hasError,
  onChange,
  ...rest
}) {
  return (
    <Select
      value={_.find(options, (opt) => opt.value === value)}
      customStyles={customStyles}
      isSearchable={false}
      isMulti={isMulti}
      isClearable={false}
      closeMenuOnSelect={isMulti ? false : true}
      className={` ${classNames} ${
        hasError ? 'custom-selection__invalid' : ''
      }`}
      classNamePrefix="custom-selection"
      placeholder={placeholder}
      options={options}
      isDisabled={disabled}
      menuPlacement="auto"
      components={{ DropdownIndicator }}
      onChange={onChange}
      {...rest}
    />
  );
}

function SelectMuslim({ options, muslimSelected, value }) {
  const optionSelected = options.map((option) => {
    return {
      value: option,
      label: option,
    };
  });
  const valueSelected = value.map((item) => {
    return {
      value: item,
      label: item,
    };
  })
  const handleChange = (value) => {
    const selected = value.map((item) => item.value);
    muslimSelected(selected);
  };
  return (
    <Select
      isMulti
      options={optionSelected}
      value={valueSelected}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={handleChange}
      styles={{
        control: (base) => ({
          ...base,
          border: '1px solid #e4e4e4',
          borderRadius: '5px',
          boxShadow: 'none',
          '&:hover': {
            border: '1px solid #00cccb',
          },
          width: '800px',
        }),
        // option: (provided, state) => ({
        //   ...provided,
        //   color: state.isSelected ? '#ffffff' : '#333333',
        //   fontWeight: state.isSelected ? 600 : 400,
        //   backgroundColor: state.isSelected ? '#00cccb' : '#ffffff',
        //   cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        // }),
        // input: (styles) => ({ ...styles, ...optionSelected() }),
        // singleValue: (styles, { data }) => ({
        //   ...styles,
        //   ...optionSelected(data),
        // }),
      }}
    />
  );
}

export { SelectMuslim };

export default SelectCustom;
