import { default as cookie } from 'js-cookie';
import ga from 'lib/ga';
import _, { get, isEmpty } from 'lodash';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { getUserProfile, searchArticles } from 'services';
import { verifyEmailCheckToken } from 'services/auth';
import { useDebounceValue } from 'usehooks-ts';
import ImgClose from '@/public/icons/ic-close.svg';
import { removeAuthorization, setAuthorization } from 'utils/axios.config';
import {
  DESTINATIONS_ALL_CITES_SLUG,
  DESTINATIONS_COUNTRIES,
  HALAL_FOOD_ALL_CITES_SLUG,
  HALAL_FOOD_COUNTRIES,
  LANGUAGES_SWITCHER,
} from '../constants';
import { clearAllDataStorage, compressHashCodeUserProfile } from '../helpers';
import Loading from './loading';
import RegisterModal from './registration/v2/RegisterV2';
import SearchArticles from './searchArticles';
import { ToastError, ToastSuccess } from './toastify';
import { IconLogout, IconSetting } from './icons/customs';

const Header = ({ isBotCrawler, allowLocales = [] }) => {
  const searchInputRef = useRef(null);
  const userId = cookie.get('user_id');
  const router = useRouter();
  const { locale = 'en' } = router;

  const [articleSearching, setArticleSearching] = useState(null);
  const [valueSearch, setValueSearch] = useDebounceValue(null, 400);
  const [cook, setCook] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedLang, setSelectedLang] = useState(locale);
  const [langOptions, setLangOptions] = useState(LANGUAGES_SWITCHER);

  const handleChange = (e) => {
    const value = e.target.value;
    setValueSearch(value?.trim());
  };

  const handleRequestSearching = async () => {
    gtag('event', 'search', {
      event_category: 'Search',
      event_label: valueSearch,
      event_value: 1,
      'dimension{metric1}': '1',
    });

    gtag('set', {
      metric1: '1',
    });

    ga.matomoEvent({
      category: 'Search',
      action: 'Search article',
      name: valueSearch,
    });

    if (valueSearch && valueSearch.length > 3) {
      const model = {
        limit: 5,
        page: 1,
        search: valueSearch,
        locale,
      };

      setLoading(true);
      searchArticles(model)
        .then((res) => {
          if (get(res, 'data.status') == 1)
            setArticleSearching(get(res, 'data'));
          else ToastError(get(res, 'data.message'));

          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  useEffect(async () => {
    const isTokenValid = router?.query?.token_valid;
    const uidb64 = router?.query?.uidb64;
    const token = router?.query?.token;
    const message = router?.query?.message;
    if (
      isTokenValid === 'True' &&
      uidb64 &&
      token &&
      message === 'Email_Login'
    ) {
      try {
        const result = await verifyEmailCheckToken({
          token: token,
          uidb64: uidb64,
        });
        const tokenAccess = get(result, 'data.data.tokens.access');
        localStorage.setItem('user_token', tokenAccess);
        setAuthorization(tokenAccess);

        handleGetUserProfile(get(result, 'data.data'));
      } catch (error) {
        console.error(error);
      }
    }
  }, [router.isReady, router.query]);

  useEffect(() => {
    if (valueSearch !== null) handleRequestSearching();
  }, [valueSearch]);

  const handleGetUserProfile = (dataLogin) => {
    if (get(dataLogin, 'user_id')) {
      getUserProfile(get(dataLogin, 'user_id'))
        .then((res) => {
          const { data } = res.data;
          if (get(res, 'data.status') == 1) {
            const fullname = get(data, 'last_name')
              ? get(data, 'first_name') + ' ' + get(data, 'last_name')
              : get(data, 'first_name');

            localStorage.setItem('logged_user_id', get(data, 'id'));
            localStorage.setItem('logged_user_name', fullname);
            localStorage.setItem('logged_user_email', get(data, 'email'));
            localStorage.setItem(
              'logged_user_lastname',
              get(data, 'last_name')
            );
            localStorage.setItem(
              'logged_user_firstname',
              get(data, 'first_name')
            );
            localStorage.setItem('logged_user_photo', get(data, 'avatar'));
            localStorage.setItem('username', fullname);

            //cookies
            cookie.set('logged_user_name', fullname);
            cookie.set('logged_user_email', get(data, 'email'));
            cookie.set('logged_user_lastname', get(data, 'last_name'));
            cookie.set('logged_user_firstname', get(data, 'first_name'));
            cookie.set('logged_user_photo', get(data, 'avatar'));
            cookie.set('username', fullname);
            cookie.set('user_id', get(data, 'id'), {
              expires: 2 / 24,
            });

            ToastSuccess('Login successfully.');
            // setTimeout(() => (window.location.href = '/'), 400);
          } else {
            const { message } = res.data;
            ToastError({ message });
          }
        })
        .catch((error) => {
          const { data } = error.response;
          ToastError(messages[data.message]);
        });
    }
  };

  useEffect(() => {
    if (!userId) {
      setCook(false);
      clearAllDataStorage();
    } else {
      setCook(true);
    }
  }, [userId]);

  // useEffect(() => {
  //   $('.val_save_1').hide();
  //   $('.val_save_2').hide();
  //   $('.val_save_3').hide();
  //   $('.val_save_4').hide();
  //   $('.val_save_5').hide();
  // }, []);

  useEffect(() => {
    const isTokenValid = router?.query?.token_valid;
    const uidb64 = router?.query?.uidb64;
    const token = router?.query?.token;
    const message = router?.query?.message;

    if (
      isTokenValid === 'True' &&
      uidb64 &&
      token &&
      message === 'Credentials Valid'
    ) {
      $('.step-1').hide();
      $('.step-8').show();
      $('#login-signup').modal('show');
    }
  }, []);

  useEffect(() => {
    if (router.query?._l == 'true') {
      clearAllDataStorage();

      setTimeout(() => {
        window.location = '/';
      }, 1500);
    }

    if (!isEmpty(router.query)) {
      $('.nav-encl .navbar-collapse').removeClass('menu-active');
      $('.menu-overlay').hide();
    }
    $('body').removeClass('overflow-hidden');
  }, [router.query]);

  useEffect(() => {
    setSelectedLang(locale);
  }, [locale]);

  useEffect(() => {
    if (allowLocales?.length > 0) {
      const filteredLangs = [];
      const langs = [...langOptions];

      langs.map((lang) => {
        if (allowLocales.includes(lang.value)) filteredLangs.push(lang);
        else return;
      });

      setLangOptions(filteredLangs);
    }
  }, [allowLocales]);

  const viewProfile = (e) => {
    e.preventDefault();
    window.location.href = '/profile';
  };

  const handleRedirect = (item) => {
    const authorSlug = compressHashCodeUserProfile();

    window.location.href =
      process.env.NEXT_PUBLIC_PROFILE_URL + item.slug + authorSlug;
  };

  const handleSearchOpenClick = () => {
    $('.float-search').slideDown('easeIn');
    searchInputRef?.current?.focus();
  };

  const handleSearchCloseClick = () => {
    $('.float-search').slideUp('easeOut');
  };

  const handleOpenLoginModal = () => {
    $('.step-1').show();
    $('.step-2').hide();
    $('.step-3').hide();
    $('.step-4').hide();
    $('.step-5').hide();
    $('.step-6').hide();
    $('.step-7').hide();
    $('.step-8').hide();
    $('.login-s-1').hide();
    $('#login-signup').modal('show');
  };

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    if (valueSearch && valueSearch.trim()) {
      router.push(`/search/${valueSearch}`, undefined, { locale });
    }
  };

  const signOut = () => {
    if (typeof window !== 'undefined') {
      removeAuthorization();
      clearAllDataStorage();
      window.location.href = '/';
    }
  };

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "Have Halal Will Travel",
              "url": "${process.env.NEXT_PUBLIC_LANDING_URL}${
              locale === 'en' ? '' : `/${locale}`
            }",
              "description": "Your ultimate guide to halal travel and lifestyle",
              "author": {
                "@type": "Organization",
                "name": "Have Halal Will Travel"
              },
              "publisher": {
                "@type": "Organization",
                "name": "Have Halal Will Travel",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://minio.havehalalwilltravel.com/hhwt-upload/old-images/hhwt_logo.png",
                  "width": 600,
                  "height": 200
                }
              }
            }
          `,
          }}
        ></script>
      </Head>

      {isLoading && <Loading />}

      <header id="pageHeader" className="container-fluid">
        <div className="header-row">
          <a
            href={locale === 'en' ? '/' : `/${locale}`}
            className="navbar-brand"
          >
            <img
              src="/images/logo-teal.png"
              alt="hhwt-logo"
              title="logo-image"
            />
          </a>
          <form className="search-hdr d-none d-lg-block">
            <div className="input-group search-lg-btn">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <img
                    width={24}
                    height={25}
                    src="/icons/ic-search.svg"
                    alt="icon"
                  />
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Search HHWT..."
                onClick={handleSearchOpenClick}
              />
            </div>
          </form>

          <div className="nav-encl">
            <nav className="navbar navbar-expand-md p-0">
              {/* navbar on mobile */}
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <div className="navbar-collapse-header only-mobile">
                  <a href={locale === 'en' ? '/' : `/${locale}`}>
                    <img
                      src="/images/logo-teal.png"
                      alt="hhwt-logo"
                      title="logo-image"
                    />
                  </a>
                  <div
                    className="close-menu"
                    onClick={() => {
                      $('.nav-encl .navbar-collapse').removeClass(
                        'menu-active'
                      );
                      $('.menu-overlay').fadeToggle(200);
                    }}
                  >
                    <img src={ImgClose.src} alt="" />
                  </div>
                </div>
                <div className="only-mobile">
                  {cook ? (
                    <a
                      href="/profile"
                      className="navbar-collapse-header-profile"
                    >
                      <img
                        className="prof-img"
                        src={
                          cookie.get('logged_user_photo') ||
                          '/images/img-hhwt-logo.png'
                        }
                        alt="profile"
                        width={40}
                        height={40}
                      />
                      <div className="navbar-collapse-header-profile-info">
                        <p className="navbar-collapse-header-profile-info-name">
                          {cookie.get('logged_user_name')}
                        </p>
                        <p className="navbar-collapse-header-profile-info-email">
                          {cookie.get('logged_user_email')}
                        </p>
                      </div>
                      <div className="navbar-collapse-header-profile-icon">
                        <img src="/icons/ic-badges.svg" alt="ic-badges" />
                      </div>
                    </a>
                  ) : null}
                </div>
                <ul className="navbar-nav">
                  {/* HALAL FOOD */}
                  <li className="nav-item dropdown">
                    <div
                      className="nav-link"
                      id="halal-food-menu"
                      role="button"
                      data-toggle="dropdown"
                      aria-roledescription="navigation toggle"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span className="menu-text">Halal Food</span>
                      <img
                        className="only-mobile"
                        src="/icons/ic-chevron-left.svg"
                        alt="icon"
                      />
                      <img
                        className="only-desktop"
                        src="/icons/ic-chevron-down.svg"
                        alt="icon"
                      />
                    </div>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="halal-food-menu"
                    >
                      {_.map(HALAL_FOOD_COUNTRIES, (item) => (
                        <li
                          key={item.id}
                          className={`${
                            item.slug?.includes(router.query?.halal)
                              ? 'item-active'
                              : ''
                          }`}
                        >
                          <Link href={item.slug}>
                            <a className="dropdown-item">{item.name}</a>
                          </Link>
                        </li>
                      ))}
                      <li className="dropdown-divider"></li>
                      <li>
                        <Link href={HALAL_FOOD_ALL_CITES_SLUG}>
                          <a className="dropdown-item">
                            View all cities&nbsp;
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/* DESTINATIONS */}
                  <li className="nav-item dropdown">
                    <div
                      className="nav-link"
                      id="destination-menu"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span className="menu-text">Destinations</span>
                      <img
                        className="only-mobile"
                        src="/icons/ic-chevron-left.svg"
                        alt="icon"
                      />
                      <img
                        className="only-desktop"
                        src="/icons/ic-chevron-down.svg"
                        alt="icon"
                      />
                    </div>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="destination-menu"
                    >
                      {_.map(DESTINATIONS_COUNTRIES, (item) => (
                        <li
                          key={item.id}
                          className={`${
                            item.slug?.includes(router.query?.travel)
                              ? 'item-active'
                              : ''
                          }`}
                        >
                          <Link href={item.slug}>
                            <a className="dropdown-item">{item.name}</a>
                          </Link>
                        </li>
                      ))}
                      <li className="dropdown-divider"></li>
                      <li>
                        <Link href={DESTINATIONS_ALL_CITES_SLUG}>
                          <a className="dropdown-item">
                            View all cities&nbsp;
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  {/* ITINERARY */}
                  {/* <li className="nav-item">
                    <Link href="/category/itinerary">
                      <a className="nav-link">
                        <span className="menu-text">Itinerary</span>
                      </a>
                    </Link>
                  </li> */}

                  {/* REWARDS */}
                  <li className="nav-item">
                    <Link href="#">
                      <a className="nav-link">
                        <span className="menu-text">Rewards</span>
                        <img
                          className="only-mobile"
                          src="/icons/ic-chevron-left.svg"
                          alt="icon"
                          
                        />
                      </a>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      onClick={() => handleRedirect({ slug: '/page/about-us' })}
                    >
                      <span className="menu-text">About Us</span>
                      <img
                        className="only-mobile"
                        src="/icons/ic-chevron-left.svg"
                        alt="icon"
                      />
                    </a>
                  </li>

                  {/* EAT THE WORLD */}
                  <li className="nav-item">
                    <a
                      href="/landing/eat-the-world"
                      className="nav-link eat-the-world-item"
                    >
                      <>
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 40 41"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20.0012 3.50732C10.6849 3.50732 3.13367 11.0586 3.13367 20.375C3.13367 27.0876 7.06495 32.8665 12.7412 35.5813L13.2692 25.3798C13.2692 25.3798 12.2761 25.0539 12.0587 24.9478C9.95241 24.02 8.42226 21.2881 8.42226 17.7425C8.42226 13.8735 11.4228 10.541 14.3237 10.5135C14.3274 10.5135 14.3274 10.5135 14.3298 10.5135C14.3311 10.5135 14.3335 10.5135 14.3346 10.5135C17.2362 10.5409 20.2368 13.8734 20.2368 17.7425C20.2368 21.2881 18.7048 24.0188 16.6027 24.9478C16.5862 24.9551 15.3826 25.3921 15.3826 25.3921L15.9723 36.7349C17.2661 37.0534 18.6114 37.2403 20.0036 37.2403C21.881 37.2403 23.6791 36.9205 25.3661 36.3552L25.8886 26.2466C24.5117 25.9438 23.3373 25.0673 22.6013 23.9676C21.6894 22.6016 23.3032 10.5123 23.3032 10.5123H24.353V21.8569H25.3955V10.5136H26.5357V21.8594H27.5733V10.5136H28.7562V21.8594H29.7974V10.5136H30.603C30.603 10.5136 32.2168 22.6029 31.3038 23.9688C30.575 25.0577 29.4116 25.9317 28.0519 26.2418C28.0275 26.2504 28.0054 26.2588 28.0054 26.2588L28.4595 34.954C33.484 32.0328 36.8702 26.6056 36.8702 20.3751C36.8677 11.0587 29.3164 3.50732 20.0012 3.50732Z"
                            fill="currentColor"
                          />
                          <path
                            d="M10.8911 16.0274C10.9515 15.7814 11.0327 15.5477 11.0364 15.5435C11.0627 15.4513 11.0138 15.3573 10.9137 15.2975C10.8185 15.2462 10.7025 15.2725 10.6488 15.3549C10.6488 15.3549 10.5335 15.5386 10.3662 15.8102C10.2301 16.044 10.0287 16.4175 9.89131 16.8826C9.77228 17.2902 9.63012 17.8323 9.56604 18.4389C9.53861 18.7576 9.53241 19.045 9.54219 19.341C9.56603 19.6785 9.6172 19.9672 9.71491 20.2974C10.0463 21.2392 10.3015 22.1511 11.2225 23.0507C11.5136 23.3498 11.8127 23.5536 12.0287 23.7038L12.105 23.7574C12.1514 23.788 12.196 23.8162 12.2399 23.8418C12.4212 23.937 12.5426 23.9711 12.5647 23.976C12.6549 24.0004 12.7478 23.9564 12.7861 23.8832C12.8222 23.8063 12.7959 23.7099 12.7209 23.6489C12.7209 23.6489 12.6158 23.5574 12.481 23.3865C12.3889 23.2827 12.2888 23.1325 12.1722 22.963C12.0899 22.8433 11.9928 22.7078 11.9024 22.5759C11.4538 21.8911 11.1952 20.9353 10.8374 19.9562C10.7672 19.7438 10.7099 19.4685 10.6884 19.2348C10.6599 18.965 10.6464 18.705 10.6464 18.4639C10.636 17.9036 10.661 17.4226 10.7099 17.0326C10.766 16.5926 10.8099 16.2624 10.8911 16.0274Z"
                            fill="currentColor"
                          />
                          <path
                            d="M20.0012 0.375C8.97403 0.375 0 9.34713 0 20.3749C0 31.4003 8.97403 40.375 20.0012 40.375C31.0279 40.375 40.0001 31.4003 40.0001 20.3749C40 9.34713 31.0279 0.375 20.0012 0.375ZM20.0012 38.8833C9.79489 38.8833 1.49107 30.5801 1.49107 20.3749C1.49107 10.1686 9.79489 1.8648 20.0012 1.8648C30.2064 1.8648 38.5108 10.1686 38.5108 20.3749C38.5107 30.5801 30.2063 38.8833 20.0012 38.8833Z"
                            fill="currentColor"
                          />
                        </svg>

                        <span className="menu-text">Eat The World</span>
                      </>
                    </a>
                  </li>

                  {cook && (
                    <li className="nav-item only-mobile">
                      <a
                        className="nav-link"
                        onClick={() =>
                          handleRedirect({ slug: '/profile/setting' })
                        }
                      >
                        <span className="menu-text">Settings</span>
                        <img
                          className="only-mobile"
                          src="/icons/ic-chevron-left.svg"
                          alt="icon"
                        />
                        <span className="menu-text">Eat The World</span>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </nav>
          </div>

          <div className="profile-block">
            {/* <div
                className="search-lg-btn individual d-lg-none"
                onClick={handleSearchOpenClick}
              >
                <img
                  width={24}
                  height={24}
                  // className="white"
                  src="/icons/ic-search.svg"
                  alt="icon"
                />
              </div> */}

            {/* LANGUAGES */}
            {/* <div className="language-switcher">
              <Select
                options={langOptions}
                value={selectedLang}
                getOptionLabel={(opt) => (
                  <div className="language-option">
                    <div className="option-flag">
                      <img src={opt.icon?.src} alt="flag-icon" />
                    </div>
                    <span className="option-label">{opt.label}</span>
                  </div>
                )}
                onChange={(opt) =>
                (window.location.href = `${process.env.NEXT_PUBLIC_LANDING_URL
                  }/${opt.value === 'en' ? '' : opt.value}/${router.asPath}`)
                }
              />
            </div> */}

            {cook ? (
              <>
                <div className="btn-login profile-dd profile-dropdown only-desktop">
                  <img
                    className="prof-img"
                    src={
                      cookie.get('logged_user_photo') ||
                      '/images/img-hhwt-logo.png'
                    }
                    alt="profile"
                    width={40}
                    height={40}
                  />
                  <div
                    className="profile-dropdown-content"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <a
                      href="/profile"
                      className="profile-dropdown-content-header"
                    >
                      <img
                        className="prof-img"
                        src={
                          cookie.get('logged_user_photo') ||
                          '/images/img-hhwt-logo.png'
                        }
                        alt="profile"
                        width={40}
                        height={40}
                      />
                      <div className="profile-dropdown-content-header-info">
                        <span className="profile-dropdown-content-header-name">
                          {cookie.get('logged_user_name')}
                        </span>
                        <span className="profile-dropdown-content-header-email">
                          {cookie.get('logged_user_email')}
                        </span>
                      </div>
                      <div className="profile-dropdown-content-header-icon">
                        <img src="/icons/ic-badges.svg" alt="ic-badges" />
                      </div>
                    </a>
                    <div className="profile-dropdown-content-body">
                      <div className="profile-dropdown-content-body-item">
                        <ul>
                          <li>
                            <a
                              className="profile-dropdown-content-body-item-link"
                              href="/profile/setting"
                            >
                              <IconSetting />
                              Settings
                            </a>
                          </li>
                          <li>
                            <div
                              className="profile-dropdown-content-body-item-link profile-dropdown-content-body-item-link-logout"
                              onClick={signOut}
                            >
                              <IconLogout />
                              Log out
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <button className="btn btn-login" onClick={handleOpenLoginModal}>
                Login
              </button>
            )}
            <button
              className="navbar-toggler only-mobile"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                $('body').toggleClass('overflow-hidden');
                window
                  .jQuery('.nav-encl .navbar-collapse')
                  .toggleClass('menu-active');
                $('.menu-overlay').fadeToggle(200);
              }}
            >
              <img src="/icons/ic-hamburger.svg" alt="icon" />
            </button>
          </div>
        </div>
      </header>

      <div className="menu-overlay"></div>

      {/* search */}
      <div className="float-search animated faster">
        <div className="container">
          <form className="search-hdr" onSubmit={handleSubmitSearch}>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <img src="/icons/ic-search.svg" alt="icon" />
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Search HHWT..."
                ref={searchInputRef}
                onChange={handleChange}
              />
            </div>
            <span className="input-close">
              <img
                className="img-fluid"
                onClick={handleSearchCloseClick}
                src="/images/close-icon.png"
                alt="icon"
              />
            </span>
          </form>
        </div>

        <SearchArticles article={articleSearching} valueSearch={valueSearch} />
      </div>

      {/* Register Modal */}
      {!isBotCrawler && <RegisterModal />}
    </>
  );
};

export default Header;
