import Button from '@/components/button';
import Input from '@/components/common/input';
import Select from '@/components/select';
import { AREA_CODES, AUTH_METHOD_LIST } from '@/constants/index';
import IcFacebookSignup from '@/public/icons/ic-fb-signup.svg';
import IcGoogleSignup from '@/public/icons/ic-google-signup.svg';
import ImgBusiness from '@/public/images/business.svg';
import { useGoogleLogin } from '@react-oauth/google';
import cookie from 'js-cookie';
import _, { get } from 'lodash';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { memo, useEffect, useRef, useState } from 'react';
import FacebookLogin from 'react-facebook-login';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
  getUserProfile,
  loginWithEmail,
  loginWithFacebook,
  loginWithGoogle,
} from 'services';
import {
  loginWithEmailToken,
  loginWithPhoneOTP,
  verifyPhoneCheckOTPLogin,
} from 'services/auth';
import { setAuthorization } from 'utils/axios.config';
import * as yup from 'yup';
import * as ga from '../../../lib/ga';
import OTPInputting from '../../auth/SignUp/Steps/OtpInputting';
import Loading from '../../loading';
import { ToastError, ToastSuccess } from '../../toastify';
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';
import Signup from '../signup';
import Link from 'next/link';

const { yupResolver } = require('@hookform/resolvers/yup');

const { PHONE_NUMBER, EMAIL } = AUTH_METHOD_LIST;

function RegisterV2() {
  const fieldRef = useRef(0);
  const router = useRouter();
  const { messages } = useIntl();

  const [captcha, setCaptcha] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  const [mainError, setMainError] = useState(false);
  const [eye, setEye] = useState(false);
  const [otp, setOtp] = useState('');
  const [endcode, setEndCode] = useState('');
  const [activeMethod, setActiveMethod] = useState('phoneNumber');
  const [name, setName] = useState('Phone Number');
  const [areaCode, setAreaCode] = useState(AREA_CODES[0].value);
  const [isLoading, setLoading] = useState(false);

  const registerSchema = yup.object().shape({
    phoneNumber: yup.lazy(() => {
      if (activeMethod === 'phoneNumber') {
        return yup.string().required('Please enter your Phone Number.');
      }
      return yup.string().notRequired();
    }),
    email: yup.lazy(() => {
      if (activeMethod === 'email') {
        return yup
          .string()
          .required('Please enter your Email.')
          .email('Please enter correct Email.');
      }
      return yup.string().notRequired();
    }),
  });

  const onLoginWithGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => handleLoginWithGoogle(tokenResponse),
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
    clearErrors,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(registerSchema),
  });

  const onSubmitCreateAccountStep = async (values) => {
    setLoading(true);
    try {
      const result =
        activeMethod === 'email'
          ? await loginWithEmailToken({
              email: values.email,
              redirect_url: process.env.NEXT_PUBLIC_LANDING_URL,
              method: 'login',
            })
          : await loginWithPhoneOTP({
              phone_number: areaCode + values.phoneNumber,
            });

      // const model = {
      //   method: activeMethod,
      //   areaCode: areaCode,
      //   value:
      //     activeMethod === PHONE_NUMBER ? values.phoneNumber : values.email,
      //   endcode_data: get(result, 'data.data.endcode_data'),
      //   expireTime: get(result, 'data.data.expire_time_otp'),
      //   type: 'login',
      // };

      if (get(result, 'data.status') == 1) {
        setLoading(false);
        ToastSuccess('Sent successfully, please check!');
        const closeButton = document.querySelector('.pop-up-login__close-btn');
        if (closeButton) closeButton.click();
      }
      if (get(result, 'data.data.endcode_data')) {
        setLoading(false);
        setEndCode(get(result, 'data.data.endcode_data'));
        ToastSuccess('Sent successfully, please check!');

        const hideMain = document.querySelector('.modal-content.pop-up-login');
        if (hideMain) {
          hideMain.style.display = 'none';
        }

        const showOTP = document.querySelector(
          '.modal-content.pop-up-login.pop-up-login-phone'
        );
        if (showOTP) showOTP.style.display = 'block';
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setMainError(false);
    $('.login-s-1').hide();
    $('.step-2').hide();
    $('.step-3').hide();
    $('.step-4').hide();
    $('.step-5').hide();
    $('.step-6').hide();
  }, []);

  const signupNav = () => {
    $('.step-1').hide();
    $('.step-2').show();
    $('.step-3').hide();
  };

  const loginNav = () => {
    $('.step-1').show();
    $('.step-2').hide();
    $('.step-3').hide();
  };

  const signupButton = () => {
    $('.step-1').hide();
    $('.step-2').hide();
    $('.step-3').show();

    ga.event({
      action: 'Publication | Sign up with Email Click',
      params: {
        category: 'Sign up',
        value: 'Email',
      },
    });
    ga.matomoEvent({
      category: 'Sign up',
      action: 'Email Link Click',
      name: 'Email',
    });
  };

  const responseFacebook = (res) => {
    console.log(res)
    if (res.accessToken) {
      loginWithFacebook({ access_token: res.accessToken })
        .then((resp) => {
          if (resp.data.status == 1) {
            const { user_id, tokens } = resp.data;
            $('#login-signup').modal('hide');

            setAuthorization(tokens?.access);
            localStorage.setItem('logged_user_id', user_id);
            localStorage.setItem('user_token', tokens?.access);

            cookie.set('user_id', user_id);
            cookie.set('token', tokens?.access);
            handleGetUserProfile({ user_id });

            ga.event({
              action: 'Publication | Login with Facebook Success',
              params: {
                category: 'Login',
                label: 'Social Login',
              },
            });
            ga.matomoEvent({
              category: 'Social Login',
              action: 'Login with Facebook Success',
              name: 'Facebook ',
            });
          } else {
            ga.event({
              action: 'Publication | Login Facebook Failure',
              params: {
                category: 'Login',
                label: 'Social Login',
              },
            });
            ga.matomoEvent({
              category: 'Social Login',
              action: 'Login Facebook Failure',
              name: 'Facebook ',
            });
          }
        })
        .catch((error) => {
          if (error.response.data) ToastError(messages[data.message]);
        });
    }
  };

  const onSubmitLoginWithEmail = (data, e) => {
    e.target.reset();

    if (captcha === true) {
      setCaptchaError(false);
      handleLoginWithEmail(data);
    } else {
      setCaptchaError(true);
    }
  };

  const handleLoginWithEmail = (data) => {
    const loginBody = {
      email: data.email,
      password: data.password,
    };

    loginWithEmail(loginBody)
      .then((res) => {
        if (res.data?.status === 1) {
          const { data } = res;
          $('#login-signup').modal('hide');

          setAuthorization(data?.tokens?.access);
          localStorage.setItem('logged_user_id', data?.user_id);
          localStorage.setItem('user_token', data?.tokens?.access);

          cookie.set('user_id', data?.user_id);
          cookie.set('token', data?.tokens?.access);
          handleGetUserProfile(data);

          ga.event({
            action: 'Publication | Login Email Success',
            params: {
              category: 'Login',
              label: 'Email Login',
            },
          });
          ga.matomoEvent({
            category: 'Email Login',
            action: 'Login Email Success',
            name: 'Email ',
          });
        } else {
          const { message } = res.data;
          ToastError(message);

          ga.event({
            action: 'Publication | Login Email Failure',
            params: {
              category: 'Login',
              label: 'Email Login',
            },
          });
          ga.matomoEvent({
            category: 'Email Login',
            action: 'Login Email Failure',
            name: 'Email ',
          });
        }
      })
      .catch((error) => {
        console.log(error);
        const { data } = error.response;
        ToastError(messages[data.message]);
      });
  };

  const handleGetUserProfile = (dataLogin) => {
    if (dataLogin?.user_id) {
      getUserProfile(dataLogin.user_id)
        .then((res) => {
          const { data } = res.data;

          if (res.data?.status == 1) {
            const fullname = data?.last_name
              ? data?.first_name + ' ' + data?.last_name
              : data?.first_name;
            localStorage.setItem('logged_user_name', fullname);
            localStorage.setItem('logged_user_email', data?.email);
            localStorage.setItem('logged_user_lastname', data?.last_name);
            localStorage.setItem('logged_user_firstname', data?.first_name);
            localStorage.setItem('logged_user_photo', data?.avatar);
            localStorage.setItem('username', fullname);

            //cookies
            cookie.set('logged_user_name', fullname);
            cookie.set('logged_user_email', data?.email);
            cookie.set('logged_user_lastname', data?.last_name);
            cookie.set('logged_user_firstname', data?.first_name);
            cookie.set('logged_user_photo', data?.avatar);
            cookie.set('username', fullname);

            cookie.set('user_id', dataLogin.user_id, {
              expires: 2 / 24,
            });
            ToastSuccess('Login successfully.');

            setTimeout(() => {
              router.reload(window.location.pathname);
            }, 1500);
          } else {
            const { message } = res.data;
            ToastError({ message });
          }
        })
        .catch((error) => {
          const { data } = error.response;
          ToastError(messages[data.message]);
        });
    }
  };

  const handleOpenSendEmail = () => {
    window
      .jQuery('.step-7 .modal-header .modal-title')
      .text(
        "Enter your email and we'll send you a link to reset your password"
      );
    $('.step-7 .email-sent').hide();
    $('.step-7 .modal-body').show();
    $('.step-1').hide();
    $('.step-7').show();
  };

  const handleLoginWithGoogle = (tokenResponse) => {
    if (tokenResponse?.access_token) {
      loginWithGoogle({ access_token: tokenResponse?.access_token })
        .then((res) => {
          if (res.data.status == 1) {
            const { user_id, tokens } = res.data;
            $('#login-signup').modal('hide');

            setAuthorization(tokens?.access);
            localStorage.setItem('logged_user_id', user_id);
            localStorage.setItem('user_token', tokens?.access);

            cookie.set('user_id', user_id);
            cookie.set('token', tokens?.access);
            handleGetUserProfile({ user_id });

            ga.event({
              action: 'Publication | Login with Google Success',
              params: {
                category: 'Login',
                label: 'Social Login',
              },
            });
            ga.matomoEvent({
              category: 'Social Login',
              action: 'Login with Google Success',
              name: 'Google ',
            });
          } else {
            const { message } = res.data;
            ToastError({ message });
          }
        })
        .catch((error) => {
          console.log(error);
          const { data } = error.response;
          ToastError(messages[data.message]);
        });
    }
  };

  const checkVerifySignupOTP = async (model) => {
    try {
      const result = await verifyPhoneCheckOTPLogin(model);
      const status = result.data?.status;

      if (status == 1) {
        const tokenAccess = result.data?.data?.access;
        const userId = result.data?.user_id;

        cookie.set('logged_tokenAccess', tokenAccess);
        setAuthorization(tokenAccess);
        
        localStorage.setItem('user_token', tokenAccess);
        localStorage.setItem('user_id', userId);

        if (userId) {
          getUserProfile(userId)
            .then((res) => {
              const { data } = res.data;

              if (res.data?.status == 1) {
                const fullname = data?.last_name
                  ? data?.first_name + ' ' + data?.last_name
                  : data?.first_name;
                localStorage.setItem('logged_user_name', fullname);
                localStorage.setItem('logged_user_email', data?.email);
                localStorage.setItem('logged_user_lastname', data?.last_name);
                localStorage.setItem('logged_user_firstname', data?.first_name);
                localStorage.setItem('logged_user_photo', data?.avatar);
                localStorage.setItem('username', fullname);
                //cookies
                cookie.set('logged_user_name', fullname);
                cookie.set('logged_user_email', data?.email);
                cookie.set('logged_user_lastname', data?.last_name);
                cookie.set('logged_user_firstname', data?.first_name);
                cookie.set('logged_user_photo', data?.avatar);
                cookie.set('username', fullname);
                cookie.set('user_id', userId, {
                  expires: 2 / 24,
                });

                ToastSuccess('Login successfully.');
                setTimeout(() => (window.location.href = '/'), 100);
              } else {
                const { message } = res.data;
                ToastError({ message });
              }
            })
            .catch((error) => {
              const { data } = error.response;
              ToastError(messages[data.message]);
            });
        }
      }
    } catch (error) {
      console.log(error);
      ToastError('Activations OTP expired or Invalid OTP');
    }
  };

  useEffect(() => {
    if (otp.length === 6) {
      const loginBody = {
        otp: otp,
        endcode_data: endcode,
      };
      checkVerifySignupOTP(loginBody);
    }
  }, [otp]);

  return (
    <React.Fragment>
      {/* <!-- Modal Starts here --> */}
      {isLoading && <Loading />}
      <div
        className="modal fade login-modal pop-up-new"
        id="login-signup"
        tabIndex="-1"
        role="dialog"
      >
        {/* Step 1 login page */}
        <div
          className="modal-dialog modal-dialog-centered step-1 pop-up-new__mobile"
          role="document"
        >
          <div className="modal-content pop-up-login">
            <div className="modal-header border-0 pb-0 pop-up-login__top">
              <h5 className="pop-up-login__title modal-title w-100">
                Welcome to HHWT
              </h5>
              <div className="pop-up-login__link">
                <span>Don’t have an account?</span>
                <span>
                  <a href="/signup"> Sign up here</a>
                </span>
              </div>
              <button
                type="button"
                className="pop-up-login__close-btn close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="/images/close-ico.svg" alt="icon" />
              </button>
            </div>
            <div className="pop-up-login__mid">
              <ul className="profile-method-selection pop-up-login__mid__action">
                {_.map(AUTH_METHOD_LIST, (method) => (
                  <li
                    key={method.value}
                    className={`pop-up-login__mid__item profile-method ${
                      method.value === activeMethod ? 'active' : ''
                    }`}
                    onClick={() => {
                      setActiveMethod(method.value);
                      setName(method.name);
                    }}
                  >
                    {method.name}
                  </li>
                ))}
              </ul>
              <form
                ref={fieldRef}
                onSubmit={handleSubmit(onSubmitCreateAccountStep)}
                className="pop-up-login__auth-form"
              >
                <div className="pop-up-login__mid__value">
                  {name === 'Phone Number' ? (
                    <div className="pop-up-login__mid__value__form-item">
                      <div className="pop-up-login__mid__value__form-item__label">
                        Phone Number
                      </div>
                      <div className="pop-up-login__mid__value__form-item__field">
                        <div className="pop-up-login__mid__value__group">
                          <div className="pop-up-login__mid__value__selection">
                            <Select
                              options={AREA_CODES}
                              value={areaCode}
                              getOptionLabel={(e) => (
                                <div
                                  className="pop-up-login__mid__value__area-code-option"
                                  style={{
                                    width: fieldRef?.current?.clientWidth
                                      ? `calc(${
                                          fieldRef.current.clientWidth - 24
                                        }px)`
                                      : 'auto',
                                  }}
                                >
                                  <div className="pop-up-login__mid__value__option-flag">
                                    <Image
                                      src={e.icon}
                                      layout="fill"
                                      alt="flag-icon"
                                    />
                                  </div>
                                  <span className="pop-up-login__mid__value__option-value">
                                    ({e.value})
                                  </span>
                                  <span className="pop-up-login__mid__value__option-label">
                                    {e.label}
                                  </span>
                                </div>
                              )}
                              onChange={(opt) => setAreaCode(opt.value)}
                            />
                          </div>
                          <div className="pop-up-login__mid__value__inputting">
                            <Input
                              type="number"
                              placeholder="Enter your Phone Number"
                              hasError={get(errors, 'phoneNumber.message')}
                              register={register('phoneNumber')}
                            />
                          </div>
                        </div>
                      </div>
                      {get(errors, 'phoneNumber.message') && (
                        <div className="form-item__err-message">
                          {get(errors, 'phoneNumber.message')}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="pop-up-login__mid__value__form-item">
                      <div className="form-item__label">Email</div>
                      <div className="form-item__field">
                        <Input
                          type="text"
                          placeholder="Enter your Email"
                          hasError={get(errors, 'email.message')}
                          register={register('email')}
                        />
                      </div>
                      {get(errors, 'email.message') && (
                        <div className="form-item__err-message">
                          {get(errors, 'email.message')}
                        </div>
                      )}
                    </div>
                  )}
                  <div className="pop-up-login__mid__value__form-item">
                    <Button
                      classNames="pop-up-login__mid__value__login-btn"
                      title="Log in"
                      type="submit"
                    />
                  </div>
                </div>
              </form>

              <div className="pop-up-login__mid__value__auth-or">
                or Log in with
              </div>
              <div className="pop-up-login__mid__value__auth-social">
                <FacebookLogin
                  cssClass="pop-up-login__mid__value__auth-btn has-icon"
                  textButton=""
                  disableMobileRedirect={true}
                  appId={process.env.NEXT_PUBLIC_FB_CLIENT_ID}
                  autoLoad={false}
                  fields="name,email,picture"
                  icon={
                    <img
                      width={24}
                      height={24}
                      src={IcFacebookSignup.src}
                      alt="icon"
                    />
                  }
                  callback={responseFacebook}
                  onClick={() => {
                    ga.event({
                      action: 'Publication | Sign up with Facebook Click',
                      params: {
                        category: 'Sign up',
                        value: 'Facebook',
                      },
                    });
                    ga.matomoEvent({
                      category: 'Social Sign up',
                      action: 'Facebook Link Click',
                      name: 'Facebook',
                    });
                  }}
                />
                <Button
                  classNames="pop-up-login__mid__value__auth-btn has-icon"
                  icon={IcGoogleSignup}
                  tooltip="Log in with Google"
                  onClick={() => {
                    ga.event({
                      action: 'Publication | Sign up with Google Click',
                      params: {
                        category: 'Sign up',
                        value: 'Google',
                      },
                    });
                    ga.matomoEvent({
                      category: 'Social Sign up',
                      action: 'Google Link Click',
                      name: 'Google',
                    });
                    onLoginWithGoogle();
                  }}
                />
              </div>
            </div>
            <div className="pop-up-login__bot">
              <div>
                By continuing, you agree to the Have Halal Will Travel{' '}
                <span className="pop-up-login__bot__span">
                  <a href="https://profile.havehalalwilltravel.com/page/end-user-license-agreement">
                    Terms of Use
                  </a>
                </span>{' '}
                and to the{' '}
                <span className="pop-up-login__bot__span">
                  <a
                    href={
                      process.env.NEXT_PUBLIC_LANDING_URL + '/privacy-policy'
                    }
                  >
                    Privacy Policy
                  </a>
                </span>
              </div>
              <div className="pop-up-login__bot__business">
                <img src={ImgBusiness.src} layout="fill" alt="business-icon" />
                <div>Are you business user? Onboard with us!</div>
              </div>
            </div>
            <div className="modal-body pt-0"></div>
          </div>
          <div className="modal-content pop-up-login pop-up-login-phone">
            <div className="modal-header border-0 pb-0 pop-up-login__top">
              <h5 className="pop-up-login__title modal-title w-100">
                Enter verify code
              </h5>
              <div className="auth-content__middle onboarding-mid">
                <div className="pop-up-login-phone__auth-verify">
                  <div className="pop-up-login-phone__auth-verify__label">
                    Verification Code
                  </div>
                  <div className={`pop-up-login-phone__auth-verify__field`}>
                    <OTPInputting otp={otp} onSetOtp={setOtp} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Step 2 signup page */}
        <div
          className="modal-dialog modal-dialog-centered step-2"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title w-100">Join the community.</h5>
              <p>
                Sign up to access your saved articles across all your devices.
              </p>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="/images/close-ico.svg" alt="icon" />
              </button>
            </div>
            <div className="modal-body pt-0">
              <div className="btn-wrap">
                <FacebookLogin
                  cssClass="btn btn-fb just-fb"
                  textButton="Continue with Facebook"
                  disableMobileRedirect={true}
                  appId={process.env.NEXT_PUBLIC_FB_CLIENT_ID}
                  autoLoad={false}
                  fields="name,email,picture"
                  icon={<img src="/images/fb-ico.svg" alt="icon" />}
                  callback={responseFacebook}
                  onClick={() => {
                    ga.event({
                      action: 'Publication | Sign up with Facebook Click',
                      params: {
                        category: 'Sign up',
                        value: 'Facebook',
                      },
                    });
                    ga.matomoEvent({
                      category: 'Social Sign up',
                      action: 'Facebook Link Click',
                      name: 'Facebook',
                    });
                  }}
                />

                <button
                  className="btn btn-google mt-2 my-1"
                  onClick={() => {
                    ga.event({
                      action: 'Publication | Sign up with Google Click',
                      params: {
                        category: 'Sign up',
                        value: 'Google',
                      },
                    });
                    ga.matomoEvent({
                      category: 'Social Sign up',
                      action: 'Google Link Click',
                      name: 'Google',
                    });
                    onLoginWithGoogle();
                  }}
                >
                  <img src="/images/google-ico.svg" alt="icon" />
                  Continue with Google
                </button>

                <button className="btn btn-email my-1" onClick={signupButton}>
                  <img src="/images/email-ico.svg" alt="icon" />
                  Sign up with email
                </button>
                <p className="terms-text mt-1">
                  By continuing with your action, you agree to Have Halal, Will
                  Travel’s{' '}
                  <Link
                    href={
                      process.env.NEXT_PUBLIC_LANDING_URL + '/privacy-policy'
                    }
                    target="_blank"
                  >
                    <a
                      onClick={() => {
                        ga.event({
                          action: 'Policy Link Click',
                          params: {
                            category: 'Policy',
                            value:
                              process.env.NEXT_PUBLIC_LANDING_URL +
                              '/privacy-policy',
                          },
                        });
                        ga.matomoEvent({
                          category: 'Policy',
                          action: 'Policy Link Click',
                          name:
                            process.env.NEXT_PUBLIC_LANDING_URL +
                            '/privacy-policy',
                        });
                      }}
                    >
                      Term of Use
                    </a>
                  </Link>
                </p>
                <div className="signup-cont">
                  Already have an account?{' '}
                  <span onClick={loginNav}>Log in!</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Step 3 signup inputs */}

        <Signup />

        <ForgotPassword />

        <ResetPassword />

        {/* Step 3 signup inputs */}
      </div>
      {/* <!-- Login Modal Ends here --> */}
    </React.Fragment>
  );
}

export default memo(RegisterV2);
