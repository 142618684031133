import Image from 'next/image';
import Link from 'next/link';
import { imageloader } from '../../helpers';
import { memo } from 'react';

function SearchArticles({ article, valueSearch }) {
  return (
    <>
      {article && valueSearch && (
        <div className="search-wrapper">
          <div className="search-content">
            {article?.results?.map((art) => (
              <Link href={`/${art?.post_name}`}>
                <a>
                  <div className="search-item">
                    <div className="img-search">
                      {art?.image?.webp && (
                        <Image
                          src={art?.image?.webp}
                          className="img-responsive"
                          layout="responsive"
                          alt="post"
                          width={48}
                          height={48}
                          loader={imageloader}
                        />
                      )}
                    </div>
                    <p>{art?.post_title}</p>
                  </div>
                </a>
              </Link>
            ))}
          </div>

          {article && (
            <div className="title-row">
              <div className="search-result">
                <Link href={`/search/${valueSearch}`}>
                  <a>
                    <div className="btn btn-viewall">
                      See all results for "{valueSearch}"
                      <i className="fa fa-angle-right"></i>
                    </div>
                  </a>
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default memo(SearchArticles);
