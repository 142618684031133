const { yupResolver } = require("@hookform/resolvers/yup");
import ga from 'lib/ga';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { resetPassword } from 'services';
import * as yup from 'yup';
import Loading from '../loading';
import { ToastError, ToastSuccess } from '../toastify';

const schema = yup.object().shape({
  password: yup.string().min(8, 'Password must be 8 characters long.')
  .matches(/[0-9]/, 'Password requires a number.')
});

function ResetPassword() {
  const router = useRouter();
  const [eye, setEye] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const model = {
      password: data.password,
      token: router?.query?.token,
      uidb64: router?.query?.uidb64,
    }

    setLoading(true);
    resetPassword(model).then(res => {
      if(res.data.status == 1) {
        setLoading(false);
        ToastSuccess("Your password has been reset.");

        ga.event({
          action: 'Reset Password Success',
          params: {
            category: 'Reset Password',
            value: 'Reset Password Success',
          },
        });
        ga.matomoEvent({
          category: 'Reset Password',
          action: 'Reset Password',
          name: 'Reset Password Success',
        });

        window.jQuery("#login-signup").modal("hide");
        router.push("/");
      }else {
        setLoading(false);
        const { message } = res.data;
        ToastError(message);

        ga.event({
          action: 'Reset Password Failure',
          params: {
            category: 'Reset Password',
            value: 'Reset Password Failure',
          },
        });
        ga.matomoEvent({
          category: 'Reset Password',
          action: 'Reset Password',
          name: 'Reset Password Failure',
        });
      }
    }).catch(error => {
      setLoading(false);
      console.log(error);
    });
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <div
        className="modal-dialog modal-dialog-centered step-8"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header border-0 pb-0">
            <h5 className="modal-title w-100">Enter your new password to reset your password</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                ga.event({
                  action: "Publication | Skip-Reset-Password",
                  params: {
                    category: "Skip Reset Password",
                    label: "Publication-Skip-Reset-Password",
                  },
                });
                ga.matomoEvent({
                  category: 'Reset Password',
                  action: 'Skip Reset Password Click',
                  name: 'Publication-Skip-Reset-Password'
                });
              }}
            >
              <img src="/images/close-ico.svg" alt="icon" />
            </button>
          </div>
          <div className="modal-body pt-0">
            <div className="btn-wrap">
              <form
                className="login-form w-100 mt-4"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-group pass-visible">
                  <input
                    className="form-control"
                    type={`${eye == true ? "text" : "password"}`}
                    placeholder="New Password"
                    {...register("password")}
                  />
                  <div
                    className="visibility-icon"
                    onClick={() => {
                      setEye(!eye);
                    }}
                  >
                    {eye ? (
                      <img
                        className="off"
                        src="/images/visibility-icon.svg"
                        alt="icon"
                      />
                    ) : (
                      <img
                        className="off"
                        src="/images/visibility-off-icon.svg"
                        alt="icon"
                      />
                    )}
                  </div>
                  {errors.password && (
                    <span className="err-msg">{errors?.password?.message}</span>
                  )}
                </div>
                <button type="submit" className="btn btn-email mb-3">Reset Password</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ResetPassword