import { useEffect, useState } from 'react';
// import Recaptcha from "react-google-recaptcha";
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { signupWithEmail } from 'services';
import * as yup from 'yup';
import * as ga from '../../lib/ga';
import Loading from '../loading';
import { ToastError, ToastSuccess } from '../toastify';
import Link from 'next/link';

const { yupResolver } = require('@hookform/resolvers/yup');
const schema = yup.object().shape({
  password: yup.string().required('Please enter your Password.').min(8),
  email: yup
    .string()
    .required('Please enter your Email.')
    .email('Please enter the correct Email.'),
  username: yup.string().required('Please enter your Username.'),
  name: yup.string().required('Please enter your Fullname.'),
});

export default function Signup() {
  const { messages } = useIntl();
  const [captcha, setCaptcha] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  const [mainError, setMainError] = useState(false);
  const [eye, setEye] = useState(false);
  const [qrData, setQrData] = useState();
  const [tfaToken, setTfaToken] = useState();
  const [tfaQrdata, setTfaQrData] = useState();
  const [tfaSecret, setTfaSecret] = useState();
  const [tfaSecretData, setTfaSecretData] = useState();
  const [tfaMailData, setTfaMailData] = useState();
  const [isLoading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // useEffect(() => {
  //   $(".step-3").show();
  //   $(".step-4").hide();
  //   $(".step-5").hide();
  //   $(".step-6").hide();
  //   $(".step-7").hide();
  // }, []);

  const loginNav = () => {
    $('.step-1').show();
    $('.step-2').hide();
    $('.step-3').hide();
  };

  const onSubmit = (data, e) => {
    if (captcha == true) {
      setCaptchaError(false);
      signupFetch(data);
    } else {
      setCaptchaError(true);
    }
    e.target.reset();
  };

  const signupFetch = async (data) => {
    const model = {
      email: data.email,
      password: data.password,
      username: data.username,
      fullname: data.name,
    };

    setLoading(true);
    signupWithEmail(model)
      .then((res) => {
        if (res.data.status == 1) {
          setLoading(false);
          ToastSuccess('Your account has been registered.');

          ga.event({
            action: 'Publication | Success',
            params: {
              category: 'Register',
              label: 'Email',
            },
          });
          $('.step-3').hide();
          $('.step-4').show();
        } else {
          const { message } = res.data;
          ToastError(message);
          setLoading(false);
          setMainError(true);

          ga.event({
            action: 'Publication | Failure',
            params: {
              category: 'Login',
              label: 'Email',
            },
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        const { data } = error.response;
        ToastError(messages[data.message]);
      });

    // const signupFetches = await fetch(
    //   "https://api.havehalalwilltravel.com/user_registration_web",
    //   signupOptions
    // );
    // const signupFetch = await signupFetches.json();

    // if (signupFetch.status === 0) {
    //   setMainError(true);
    //   ga.event({
    //     action: "Publication | Failure",
    //     params: {
    //       category: "Login",
    //       label: "Email",
    //     },
    //   });
    // } else {
    //   ga.event({
    //     action: "Publication | Success",
    //     params: {
    //       category: "Register",
    //       label: "Email",
    //     },
    //   });
    //   // localStorage.setItem("user_id", signupFetch?.userdetails?.user_id);
    //   localStorage.setItem("email", signupFetch?.userdetails?.email);

    //   // cookie.set("user_id", signupFetch?.userdetails?.user_id, {
    //   //   expires: 1 / 24,
    //   // });
    //   localStorage.setItem("user_id", signupFetch?.userdetails?.user_id);
    //   localStorage.setItem("logged_user_name", signupFetch?.userdetails?.name);
    //   localStorage.setItem(
    //     "logged_user_email",
    //     signupFetch?.userdetails?.email
    //   );
    //   localStorage.setItem(
    //     "logged_user_lastname",
    //     signupFetch?.userdetails?.lastname
    //   );
    //   localStorage.setItem(
    //     "logged_user_firstname",
    //     signupFetch?.userdetails?.firstname
    //   );
    //   localStorage.setItem(
    //     "logged_user_photo",
    //     signupFetch?.userdetails?.profile_pic
    //   );
    //   localStorage.setItem("username", signupFetch?.userdetails?.user_name);

    //   // localStorage.setItem("user_id", loginFetch?.userdetails?.user_id);
    //   //cookiessss
    //   cookie.set("logged_user_name", signupFetch?.userdetails?.name);
    //   cookie.set("logged_user_email", signupFetch?.userdetails?.email);
    //   cookie.set("logged_user_lastname", signupFetch?.userdetails?.lastname);
    //   cookie.set("logged_user_firstname", signupFetch?.userdetails?.firstname);
    //   cookie.set("logged_user_photo", signupFetch?.userdetails?.profile_pic);
    //   cookie.set("username", signupFetch?.userdetails?.user_name);

    //   cookie.set("user_id", signupFetch.userdetails.user_id, {
    //     expires: 2 / 24,
    //   });
    //   setUserDetails(signupFetch?.userdetails);
    //   $(".step-3").hide();
    //   // $(".staus-1").show();
    //   // $(".staus-2").hide();
    //   $(".step-4").show();
    //   // window.location.reload();
    // }
  };

  const qrMfa = async () => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    const user_id = localStorage.getItem('logged_user_id');
    const email = localStorage.getItem('logged_user_email');

    // const email = await userDetails.email;
    // const user_id = 102256;
    // const email = "were@yop.com";

    var mfa_type = 1;

    var mfaqrOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        user_id: user_id,
        email: email,
        mfa_type: mfa_type,
      }),
      redirect: 'follow',
    };

    const mfaqrFetches = await fetch(
      'https://api.havehalalwilltravel.com/enable_tf_authentication',
      mfaqrOptions
    );
    const mfaqrFetch = await mfaqrFetches.json();

    // console.log("mfaqrFetch", mfaqrFetch);

    setQrData(mfaqrFetch);

    await $('.step-4').hide();
    await $('.step-5').show();
  };

  const secretMfa = async () => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    const user_id = localStorage.getItem('logged_user_id');
    const email = localStorage.getItem('logged_user_email');
    // const email = await userDetails.email;
    // const user_id = 102256;
    // const email = "were@yop.com";

    var mfa_type = 2;

    var mfaqrOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        user_id: user_id,
        email: email,
        mfa_type: mfa_type,
      }),
      redirect: 'follow',
    };

    const mfaqrFetches = await fetch(
      'https://api.havehalalwilltravel.com/enable_tf_authentication',
      mfaqrOptions
    );
    const mfaqrFetch = await mfaqrFetches.json();

    // console.log("mfaqrFetch", mfaqrFetch);

    setQrData(mfaqrFetch);

    await $('.step-4').hide();
    await $('.step-5').hide();
    await $('.step-6').show();
  };

  const mfaToken = async () => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    const user_id = localStorage.getItem('logged_user_id');
    const email = localStorage.getItem('logged_user_email');
    const tfa_token = tfaToken;
    var mfa_type = 1;

    var tfaQrOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        user_id: user_id,
        email: email,
        tfa_token: tfa_token,
        mfa_type: mfa_type,
      }),
      redirect: 'follow',
    };

    const tfaqrFetches = await fetch(
      'https://api.havehalalwilltravel.com/verify_tfa_key',
      tfaQrOptions
    );
    const tfaqrFetch = await tfaqrFetches.json();
    if (tfaqrFetch.status == 0) {
    } else {
      setTfaQrData(tfaqrFetch);
      await $('.step-5').hide();
    }
  };

  const mfaSecret = async () => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    const user_id = localStorage.getItem('logged_user_id');
    const email = localStorage.getItem('logged_user_email');
    const tfa_token = tfaSecret;
    var mfa_type = 2;

    var tfaSecretOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        user_id: user_id,
        email: email,
        tfa_token: tfa_token,
        mfa_type: mfa_type,
      }),
      redirect: 'follow',
    };

    const tfaSecretFetches = await fetch(
      'https://api.havehalalwilltravel.com/verify_tfa_key',
      tfaSecretOptions
    );
    const tfaSecretFetch = await tfaSecretFetches.json();
    setTfaSecretData(tfaSecretFetch);

    await $('.step-6').hide();
  };

  const mfaMail = async () => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    const user_id = localStorage.getItem('logged_user_id');
    const email = localStorage.getItem('logged_user_email');
    var mfa_type = 3;

    var tfaMailOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        user_id: user_id,
        email: email,
        mfa_type: mfa_type,
      }),
      redirect: 'follow',
    };

    const tfaMailFetches = await fetch(
      'https://api.havehalalwilltravel.com/enable_tf_authentication',
      tfaMailOptions
    );
    const tfaMailFetch = await tfaMailFetches.json();

    setTfaMailData(tfaMailFetch);

    await $('.step-4').hide();
    $('#signup-modal').modal('hide');
  };

  return (
    <>
      {isLoading && <Loading />}
      <div
        className="modal-dialog modal-dialog-centered step-3"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header border-0 pb-0">
            <h5 className="modal-title w-100">Create your account</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                ga.event({
                  action: 'Publication | Skip-Registeration',
                  params: {
                    category: 'Skip Registeration',
                    label: 'Publication-Skip-Registeration',
                  },
                });
              }}
            >
              <img src="/images/close-ico.svg" alt="icon" />
            </button>
          </div>
          <div className="modal-body pt-0">
            <div className="btn-wrap">
              <form
                className="login-form w-100 mt-4"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Username"
                    {...register('username')}
                  />
                  {errors?.username && (
                    <span className="err-msg">{errors.username?.message}</span>
                  )}
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Full Name"
                    {...register('name')}
                  />
                  {errors?.name && (
                    <span className="err-msg">{errors.name?.message}</span>
                  )}
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Email"
                    {...register('email')}
                  />
                  {errors?.email && (
                    <span className="err-msg">{errors.email?.message}</span>
                  )}
                </div>
                <div className="form-group pass-visible">
                  <input
                    className="form-control"
                    type={`${eye == true ? 'text' : 'password'}`}
                    placeholder="Password"
                    {...register('password')}
                  />
                  {errors.password && (
                    <span className="err-msg">{errors.password?.message}</span>
                  )}
                  <div
                    className="visibility-icon"
                    onClick={() => {
                      setEye(!eye);
                    }}
                  >
                    {eye ? (
                      <img
                        className="off"
                        src="/images/visibility-icon.svg"
                        alt="icon"
                      />
                    ) : (
                      <img
                        className="off"
                        src="/images/visibility-off-icon.svg"
                        alt="icon"
                      />
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <p className="pass-tag">
                    Password should contain atleast 8 characters with
                    combination of alphanumeric, special characters and one
                    uppercase letter.
                  </p>
                </div>
                {/* <Recaptcha
                  className="mb-2 mx-5 w-100"
                  sitekey={process.env.NEXT_PUBLIC_GG_RECAPTCHA}
                  onChange={(value) => value && setCaptcha(true)}
                  type={"image"}
                  size={"compact"}
                /> */}
                {captchaError == true ? (
                  <span className="err-msg mb-2">Please verify Captcha.</span>
                ) : (
                  ''
                )}
                <button type="submit" className="btn btn-email mb-3">
                  Signup
                </button>
                <div className="form-group">
                  <label className="custom-checkbox mb-0">
                    <input type="checkbox" name="cb" />
                    Allow HHWT to send me push notifications and content related
                    to travel
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="form-group">
                  <label className="custom-checkbox">
                    <input type="checkbox" name="cb" />I agree to Have Halal,
                    Will Travel’s{' '}
                    <Link
                      className="terms-link"
                      target="_blank"
                      href={
                        process.env.NEXT_PUBLIC_LANDING_URL + '/privacy-policy'
                      }
                    >
                      Terms Of Use
                    </Link>
                    <span className="checkmark"></span>
                  </label>
                </div>
              </form>
              <div className="signup-cont">
                Already have an account? <span onClick={loginNav}>Login</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal MultiFactor Starts here --> */}
      <div
        className="modal-dialog modal-dialog-centered step-4"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header border-0 pb-0 pl-4">
            <h4 className="modal-title w-100 pr-4">
              Multi Factor Authentication
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src="/images/close-ico.svg" alt="icon" />
            </button>
          </div>
          <div className="modal-body p-4 mt-1">
            <p className="auth-type-txt">
              IF you have need multi face Authentication?
            </p>
            <form className="login-form w-100 mt-2">
              <div className="form-group mb-1">
                <label className="custom-checkbox mb-0">
                  <input type="checkbox" name="cb" onChange={() => qrMfa()} />
                  Via QR code
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="form-group mb-1">
                <label className="custom-checkbox mb-0">
                  <input
                    type="checkbox"
                    name="cb"
                    onChange={() => secretMfa()}
                  />
                  Via Secret key on mail
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="form-group mb-1">
                <label className="custom-checkbox mb-0">
                  <input type="checkbox" name="cb" onChange={() => mfaMail()} />
                  Via Token on mail
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="form-group text-right mt-2 mb-0">
                <button className="btn btn-teal">Close</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <!-- Modal MultiFactor Ends here --> */}

      {/* qr modal */}
      <div
        className="modal-dialog modal-dialog-centered step-5"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header border-0 pb-0 pl-4">
            <h4 className="modal-title w-100 pr-4">
              Multi Factor Authentication
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src="/images/close-ico.svg" alt="icon" />
            </button>
          </div>
          <div className="modal-body p-4 mt-1">
            <p>key generated for 2FA. Please verify through QR scan</p>
            <img className="" src={qrData && qrData?.image_data} />
            <form>
              <label>Please enter your scanned qr token</label>
              <br />
              <input
                type="text"
                onChange={(e) => {
                  setTfaToken(e.target.value);
                }}
              />
              {tfaQrdata && (
                <div className="alert alert-danger mt-2">
                  Invalid 2FA key and token
                </div>
              )}
              <div className="form-group text-right mt-2 mb-0">
                <button
                  type="button"
                  className="btn btn-teal mr-2"
                  data-dismiss="modal"
                  onClick={() => mfaToken()}
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-teal"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* qr modal ends here */}

      {/* secret key */}
      <div
        className="modal-dialog modal-dialog-centered step-6"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header border-0 pb-0 pl-4">
            <h4 className="modal-title w-100 pr-4">
              Multi Factor Authentication
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src="/images/close-ico.svg" alt="icon" />
            </button>
          </div>
          <div className="modal-body p-4 mt-1">
            <p>
              key generated for 2FA. Please check your mail to get key and
              manually enter in on Google authenticator app and verify the token
            </p>
            <form>
              <input
                type="text"
                onChange={(e) => {
                  setTfaSecret(e.target.value);
                }}
              />
              <div className="form-group text-right mt-2 mb-0">
                <button
                  type="button"
                  className="btn btn-teal mr-2"
                  data-dismiss="modal"
                  onClick={() => mfaSecret()}
                >
                  Submit
                </button>
                <button
                  type="button"
                  className="btn btn-teal"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* secret key ends */}
      {/* Sign up modal ends here */}
    </>
  );
}
