import LoadingIcon from '@/public/icons/ic-loading.gif';
import Image from 'next/image';

function Loading() {
  return (
    <div className="loaderCnt">
      <div className="loading-icon">
        <Image src={LoadingIcon} alt="Loading" title="Loading" />
      </div>
    </div>
  );
}

export default Loading;
