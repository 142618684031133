import OtpInput from 'react-otp-input';

export default function OTPInputting({ otp, onSetOtp }) {
  return (
    <OtpInput
      value={otp}
      numInputs={6}
      renderInput={(props) => (
        <input {...props} type="number" placeholder="_" />
      )}
      shouldAutoFocus={true}
      onChange={onSetOtp}
    />
  );
}
