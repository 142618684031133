import ga from 'lib/ga';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { submitSendLinkToEmail } from 'services';
import * as yup from 'yup';
import Loading from '../loading';
import { ToastError } from '../toastify';
const { yupResolver } = require('@hookform/resolvers/yup');

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter your correct Email.')
    .required('Please enter your Email.'),
});

function ForgotPassword() {
  const [isLoading, setLoading] = useState(false);

  const signupNav = () => {
    $('.step-1').hide();
    $('.step-2').show();
    $('.step-3').hide();
  };

  useEffect(() => {
    $('.step-7 .email-sent').hide();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data, e) => {
    const model = {
      email: data.email,
      redirect_url: process.env.NEXT_PUBLIC_LANDING_URL,
    };

    setLoading(true);
    submitSendLinkToEmail(model)
      .then((res) => {
        if (res.data.status == 1) {
          setLoading(false);
          window
            .jQuery('.step-7 .modal-header .modal-title')
            .text('Email Sent');
          $('.step-7 .email-sent').show();
          $('.step-7 .modal-body').hide();

          e.target.reset();
        } else {
          const { message } = res.data;
          ToastError({ message });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <div
        className="modal-dialog modal-dialog-centered step-7"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header border-0 pb-0">
            <h5 className="modal-title w-100">
              Enter your email and we'll send you a link to reset your password
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                ga.event({
                  action: 'Publication | Skip-Forgot-Password',
                  params: {
                    category: 'Skip Forgot Password',
                    label: 'Publication-Skip-Forgot-Password',
                  },
                });
                ga.matomoEvent({
                  category: 'Skip Forgot Password',
                  action: 'Skip Forgot Password Click',
                  label: 'Publication-Skip-Forgot-Password',
                });
              }}
            >
              <img src="/images/close-ico.svg" alt="icon" />
            </button>
          </div>
          <div className="modal-body pt-0">
            <div className="btn-wrap">
              <form
                className="login-form w-100 mt-4"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Email"
                    {...register('email')}
                  />
                  {errors.email && (
                    <span className="err-msg">{errors?.email?.message}</span>
                  )}
                </div>
                <button type="submit" className="btn btn-email mb-3">
                  Send link to email
                </button>
              </form>
              <div className="signup-cont">
                New to HHWT? <span onClick={signupNav}>Sign up!</span>
              </div>
            </div>
          </div>

          <div className="email-sent">
            <div className="email-sent__description">
              Check your email and open the link we sent to continue
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ForgotPassword;
