import Image from 'next/image';
import { forwardRef, useImperativeHandle, useRef } from 'react';

const Input = forwardRef(function MyInput(
  {
    type = 'text',
    label = '',
    required,
    icon,
    value,
    name,
    hasError,
    readOnly = false,
    disabled = false,
    onFocus,
    register,
    ...rest
  },
  ref
) {
  const inputRef = useRef();
  useImperativeHandle(
    ref,
    () => {
      return {
        focus() {
          inputRef.current.focus();
        },
      };
    },
    []
  );

  return (
    <div
      className={`custom-input ${
        type === 'textarea' ? 'custom-textarea' : ''
      } ${disabled ? 'disabled' : ''} input-${type}
      ${hasError ? 'has-error' : ''}
      `}
    >
      {icon && (
        <div className="icon">
          <Image src={icon} layout="fill" alt="input-icon" />
        </div>
      )}
      {type === 'time' && label && <div className="label">{label}</div>}
      {type !== 'textarea' ? (
        <input
          ref={inputRef}
          type={type}
          value={value}
          readOnly={readOnly}
          disabled={disabled}
          onFocus={onFocus}
          {...register}
          {...rest}
        />
      ) : (
        <textarea value={value} readOnly={readOnly} {...rest} />
      )}
    </div>
  );
});

export default Input;



